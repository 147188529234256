@use 'styles/base/variables';

.technicalDrawings {
  margin-bottom: 1.875rem;

  &Headline {
    margin-bottom: 1.875rem;
  }

  &Imgs {
    border: variables.$border-grey;
    margin-bottom: 1.875rem;
  }
}
