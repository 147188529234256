@use 'styles/base/mixins';
@use 'styles/base/variables';

.headline {
  h1 {
    margin-bottom: 2rem;

    :global(.xy) & {
      margin-top: 0;
      margin-bottom: 0.625rem;

      @include mixins.screen-md {
        margin-bottom: 2rem;
      }
    }
  }
}

.gddsHeaderIntroText {
  @include mixins.typography-body-1();

  margin-bottom: 2rem;
}

.introText {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 2rem;
}

:local(.footerBelow) {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerPrice {
  text-align: right;
  font-weight: variables.$font-weight-bold;
}

.loaderWrapper {
  justify-content: center;
  align-items: center;
}

.editButton:hover {
  color: #000;
  background-color: variables.$gb_black_08;
}

@keyframes slideInFromRight {
  from {
    right: -300px;
  }
  to {
    right: 2rem;
  }
}

@keyframes slideInFromBottom {
  from {
    bottom: -200px;
  }
  to {
    bottom: 0;
  }
}

.notification {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 3rem;
  background-color: #fff;
  max-width: 23rem;
  z-index: 2147483649;
  padding: 1rem;
  box-shadow: 0px 4px 16px 0px variables.$gb_black_26;
  animation: slideInFromRight variables.$gb_transitionTiming variables.$gb_transitionNormal;
  right: 2rem;

  @include mixins.below-screen-sm {
    max-width: 100%;
    top: unset;
    bottom: -200px;
    bottom: 0;
    left: 0;
    right: 0;
    animation: slideInFromBottom variables.$gb_transitionTiming variables.$gb_transitionNormal;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: variables.$font-weight-medium;
  line-height: 1.75rem;
}

.text {
  margin-bottom: variables.$spacing-s;
}

.close {
  margin: 0.625rem;
  flex-grow: unset !important;
}
